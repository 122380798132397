<template>
  <div>
    <!-- 上半部 -->
    <div id="ParticipantTopArea" class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1 d-flex flex-wrap align-items-center">
        <!-- 批次操作 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="ParticipantBatch"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            批次操作
          </button>
          <ul class="dropdown-menu" aria-labelledby="ParticipantBatch">
            <li>
              <button class="dropdown-item" @click="showModal('verify')">
                審核
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('del')">
                刪除
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('restore')">
                復原
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('batchCheckOut')">
                批次結單
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('updateWallet')">
                新增購物金
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('setVip')">
                設定VIP等級
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="showModal('batchSetVip')">
                批次轉換VIP身分
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('batchSetCustomerGroup')"
              >
                批次轉換顧客群組
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('mergeParticipant', 'p_merge_v')"
              >
                <span class="text-primary fw-bolder">實體顧客</span> 併 <span class="text-danger fw-bolder">虛擬顧客</span>
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('mergeParticipant', 'v_merge_v')"
              >
                <span class="text-danger fw-bolder">虛擬顧客</span> 併 <span class="text-danger fw-bolder">虛擬顧客</span>
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('mergeParticipant', 's_merge_s')"
              >
                <span class="text-success fw-bolder">社群顧客</span> 併 <span class="text-success fw-bolder">社群顧客</span>
              </button>
            </li>
          </ul>
        </div>
        <!-- 執行 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="ParticipantAction"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            執行
          </button>
          <ul class="dropdown-menu" aria-labelledby="ParticipantAction">
            <li>
              <button class="dropdown-item" @click="showModal('exportExcel')">
                匯出
              </button>
            </li>
          </ul>
        </div>
        <!-- 通知 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="ParticipantNotice"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            通知
          </button>
          <ul class="dropdown-menu" aria-labelledby="ParticipantNotice">
            <li>
              <button
                class="dropdown-item"
                @click="showModal('lineNotifyGeneral')"
              >
                Line Notify通知 (群發)
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('lineNotifyGroupA')"
              >
                Line Notify通知 (群發：已配單但未結單)
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="showModal('lineNotifyGroupB')"
              >
                Line Notify通知 (群發：已結單但未付款)
              </button>
            </li>
          </ul>
        </div>
        <!-- 新增虛擬顧客 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('virtualCustomer')"
        >
          新增虛擬顧客
        </button>
        <!-- 常用搜尋 -->
        <div class="dropdown d-inline-block me-1 mb-1">
          <button
            class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
            id="FrequentlySearched"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            常用搜尋
          </button>
          <ul class="dropdown-menu" aria-labelledby="FrequentlySearched">
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('已刪除')"
              >
                已刪除
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('審核未通過')"
              >
                審核未通過
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('尚未審核')"
              >
                尚未審核
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('已配未結 > 0')"
              >
                已配未結 > 0
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="frequentlySearched('全到貨')"
              >
                全到貨
              </button>
            </li>
          </ul>
        </div>
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
        <!-- 前往新版顧客管理 -->
        <router-link
          class="me-1 mb-1 tw-btn tw-btn-success tw-btn-hover"
          :to="`/seller/store/${storeId}/participant`"
          target="_blank"
        >
          前往新版顧客管理
        </router-link>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span class="badge bg-primary me-1 mb-1">
            顧客狀態:
            {{ recordAdvancedSearchData.singleSelect.participantStatus }}
          </span>
          <span class="badge bg-primary me-1 mb-1">
            合併狀態:
            {{ recordAdvancedSearchData.singleSelect.mergeStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.allocatedAndCheckout !==
              '不選擇'
            "
          >
            配單/結單:
            {{ recordAdvancedSearchData.singleSelect.allocatedAndCheckout }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.uncheckoutStatus !==
              '不選擇'
            "
          >
            未結單金額狀態:
            {{ recordAdvancedSearchData.singleSelect.uncheckoutStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.walletStatus !== '不選擇'
            "
          >
            購物金餘額:
            {{ recordAdvancedSearchData.singleSelect.walletStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.singleSelect.vipStatus !== '不選擇'"
          >
            顧客 VIP:
            {{ recordAdvancedSearchData.singleSelect.vipStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.participantHabitA !==
              '不選擇'
            "
          >
            顧客習性 1:
            {{ recordAdvancedSearchData.singleSelect.participantHabitA }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.participantHabitB !==
              '不選擇'
            "
          >
            顧客習性 2:
            {{ recordAdvancedSearchData.singleSelect.participantHabitB }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.arrivalStatus !==
              '不選擇'
            "
          >
            到貨狀態:
            {{ recordAdvancedSearchData.singleSelect.arrivalStatus }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="
              recordAdvancedSearchData.singleSelect.searchByContent !== '不選擇'
            "
          >
            依內容搜尋:
            {{ recordAdvancedSearchData.singleSelect.searchByContent }}
          </span>
        </p>
        <p>
          <span v-if="recordAdvancedSearchData.billTimeRange.switch">
            帳單區間:
            <span
              v-if="
                recordAdvancedSearchData.billTimeRange.startTime &&
                recordAdvancedSearchData.billTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.billTimeRange.startTime }}~{{
                recordAdvancedSearchData.billTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.billTimeRange.startTime &&
                !recordAdvancedSearchData.billTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.billTimeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.billTimeRange.startTime &&
                recordAdvancedSearchData.billTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.billTimeRange.endTime }}以前</span
            >&ensp;
          </span>
          <span v-if="recordAdvancedSearchData.orderTimeRange.switch">
            訂單區間:
            <span
              v-if="
                recordAdvancedSearchData.orderTimeRange.startTime &&
                recordAdvancedSearchData.orderTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.orderTimeRange.startTime }}~{{
                recordAdvancedSearchData.orderTimeRange.endTime
              }}</span
            >
            <span
              v-if="
                recordAdvancedSearchData.orderTimeRange.startTime &&
                !recordAdvancedSearchData.orderTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.orderTimeRange.startTime }}開始</span
            >
            <span
              v-if="
                !recordAdvancedSearchData.orderTimeRange.startTime &&
                recordAdvancedSearchData.orderTimeRange.endTime
              "
              >{{ recordAdvancedSearchData.orderTimeRange.endTime }}以前</span
            >&ensp;
          </span>
        </p>
      </div>
    </div>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="participantList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="D4Row"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-participant-local"
          sortField=""
          :sortOrder="-1"
          v-model:selection="selectItems"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <FieldFilter
                whitchOneTable="participant"
                :D4FieldFilter="fieldFilter"
              ></FieldFilter>
              <div class="w-100">
                <p>表格搜尋</p>
                <input
                  class="form-control me-2"
                  v-model="searchGlobal['global'].value"
                  placeholder="表格搜尋: 顧客姓名，未配，已配未結，未付款，未寄出，購物金，未結單金額，最後登入時間"
                  style="max-width: 99%"
                />
              </div>
            </div>
          </template>
          <!-- 選取 -->
          <Column
            v-if="fieldFilter[0].selected"
            selectionMode="multiple"
            style="max-width: 50px"
          ></Column>
          <!-- 大頭照 -->
          <Column
            v-if="fieldFilter[1].selected"
            field=""
            header="大頭照"
            style="min-width: 68px; max-width: 68px"
          >
            <template #body="{ data }">
              <p class="mb-2 fw-bolder" v-if="data.user.possibleToMerge === false">
                <span class="border border-success text-success" v-if="!data.user.masterId">主要帳</span>
                <span class="border border-danger text-danger" v-else>被併帳</span>
              </p>
              <div v-if="data.lineProfilePicture || data.fbProfilePicture">
                <img
                  class="table-img border border-primary border-3"
                  v-if="data.fbProfilePicture"
                  :src="data.fbProfilePicture"
                  alt=""
                />
                <img
                  class="table-img border border-success border-3"
                  v-if="data.lineProfilePicture"
                  :src="data.lineProfilePicture"
                  alt=""
                />
              </div>
              <img
                class="table-img"
                v-else
                src="@/assets/other-images/nobody.png"
                alt=""
              />
            </template>
          </Column>
          <!-- 狀態 -->
          <Column
            v-if="fieldFilter[2].selected"
            field=""
            header="狀態"
            style="min-width: 50px; max-width: 50px"
          >
            <template #body="{ data }">
              <div v-if="data.userType === 'VIRTUALUSER'">
                <img
                  class="icon me-1"
                  title="虛擬顧客"
                  src="@/assets/icon/user.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  class="icon me-1"
                  title="顧客有綁定FB帳號"
                  src="@/assets/icon/facebook-2.jpg"
                  alt=""
                  v-if="data.userFacebookLink.appUserId"
                />

                <img
                  class="icon me-1"
                  title="顧客有綁定Line帳號"
                  src="@/assets/icon/line-icon.png"
                  alt=""
                  v-if="data.userLineLink.appUserId"
                />

                <img
                  class="icon"
                  title="顧客有綁定Line Notify"
                  src="@/assets/icon/linenotify.png"
                  alt=""
                  v-if="data.userLineLink.notifyAccessToken"
                />
              </div>
            </template>
          </Column>
          <!-- 顧客 -->
          <Column
            v-if="fieldFilter[3].selected"
            field="name"
            header="顧客"
            sortable
            style="min-width: 200px; max-width: 200px"
          >
            <template #body="{ data }">
              <div class="mb-1">
                <p
                  class="badge bg-warning text-dark m-0 pointer can-click"
                  v-if="data.verify === null"
                  @click="showModal('verify', data)"
                >
                  待審核
                </p>
                <p
                  class="badge bg-danger can-click m-0"
                  v-if="data.verify === false"
                  @click="showModal('verify', data)"
                >
                  審核不通過
                </p>
                <p
                  class="text-danger tw-text-size12 m-0 pointer can-click"
                  @click="showModal('setForbidden', data)"
                  v-if="data.forbidden"
                >
                  禁止個人賣場
                </p>
              </div>
              <div class="mb-1">
                <span class="me-2 tw-text-size14">顧客ID：{{ data.id }}</span>
                <span class="text-danger" v-if="data.deleted">(刪除)</span>
              </div>
              <div class="mb-1">
                <router-link
                  :to="`/seller/store/${storeId}/participant/${data.id}/merchOrder`"
                  class="text-primary fw-bolder"
                  target="_blank"
                  >{{ data.name }}</router-link
                >
                <span
                  class="tw-text-size14 text-success pointer can-click"
                  title="顧客暱稱"
                  v-if="data.nickName"
                  @click="showModal('editNickName', data)"
                >
                  | {{ data.nickName }}</span
                >
              </div>
              <!-- line -->
              <div class="mb-1" v-if="data.userLineLink.appUserId">
                <p>
                  <img
                    class="icon"
                    title="顧客的 Line 名稱"
                    src="@/assets/icon/line-icon.png"
                    alt=""
                  />&ensp;{{ data.userLineLink.name }}
                </p>
              </div>
              <!-- fb -->
              <div class="mb-1" v-if="data.userFacebookLink.name">
                <p>
                  <img class="icon" src="@/assets/icon/facebook-2.jpg" title="顧客的 Facebook 名稱" alt="" />&ensp;{{ data.userFacebookLink.name }}
                  <span class="badge rounded-pill bg-danger" v-if="data.userFacebookLink.personId && data.userType === 'VIRTUALUSER'">未</span>
                </p>
              </div>
              <!-- vip -->
              <div class="mb-1" title="顧客的VIP" v-if="data.storeVipId">
                <img class="icon me-1" src="@/assets/icon/crown1.png" alt="" />
                <span
                  class="tw-text-size14 pointer can-click"
                  @click="showModal('setVip', data)"
                  >VIP{{ data.storeVip.level }}：{{ data.storeVip.name }}</span
                >
              </div>
            </template>
          </Column>
          <!-- 顧客群組 -->
          <Column
            v-if="fieldFilter[4].selected"
            field="customerGroupId"
            header="顧客群組"
            sortable
            style="min-width: 75px; max-width: 75px"
            class="text-center"
          >
            <template #body="{ data }">
              <div
                class="pointer can-click"
                @click="showModal('editCustomerGroup', data)"
              >
                <span v-if="data.customerGroupId">{{
                  data.customerGroup.name
                }}</span>
                <span v-else>無</span>
              </div>
            </template>
          </Column>
          <!-- 操作 -->
          <Column
            v-if="fieldFilter[5].selected"
            field=""
            header="操作"
            style="min-width: 40px"
          >
            <template #body="{ data }">
              <div class="dropdown dropend pointer">
                <img
                  src="@/assets/icon/settings.png"
                  alt=""
                  id="Setting"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style="width: 25px; height: 25px"
                />
                <ul class="dropdown-menu" aria-labelledby="Setting">
                  <!-- 123 -->
                  <li v-if="data.userType === 'VIRTUALUSER'">
                    <button
                      class="dropdown-item"
                      @click="showModal('editName', data)"
                    >
                      編輯名稱
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('editNickName', data)"
                    >
                      編輯暱稱
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('setVip', data)"
                    >
                      設定VIP等級
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      @click="showModal('setForbidden', data)"
                    >
                      禁止/開放 個人賣場
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      v-if="data.verify === null"
                      @click="showModal('verify', data)"
                    >
                      審核
                    </button>
                  </li>
                  <li>
                    <router-link
                      class="dropdown-item"
                      :to="`/seller/store/${storeId}/checkoutOrder/${data.id}?status=preCheckout`"
                      >結單</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/seller/store/${storeId}/checkoutOrder/${data.id}?status=allocatedCheckout`"
                      class="dropdown-item"
                      >已配商品結單</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="`/seller/store/${storeId}/participant/${data.id}/merchOrder`"
                      class="dropdown-item"
                      >顧客詳細資訊</router-link
                    >
                  </li>
                </ul>
              </div>
            </template>
          </Column>
          <!-- 未配 -->
          <Column
            v-if="fieldFilter[6].selected"
            field="unAllocatedQuantity"
            header="未配"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <span
                v-if="tableAreaLoading"
                class="spinner-border spinner-border-sm"
              />
              <p v-else>{{ data.unAllocatedQuantity }}</p>
            </template>
          </Column>
          <!-- 已配未結 -->
          <Column
            v-if="fieldFilter[7].selected"
            field="allocatedButUnCheckOutQuantity"
            header="已配未結"
            style="min-width: 75px; max-width: 75px"
            sortable
          >
            <template #body="{ data }">
              <span
                v-if="tableAreaLoading"
                class="spinner-border spinner-border-sm"
              />
              <p v-else>{{ data.allocatedButUnCheckOutQuantity }}</p>
            </template>
          </Column>
          <!-- 未付款 -->
          <Column
            v-if="fieldFilter[8].selected"
            field="unPaidQuantity"
            header="未付款"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <span
                v-if="tableAreaLoading"
                class="spinner-border spinner-border-sm"
              />
              <p v-else>{{ data.unPaidQuantity }}</p>
            </template>
          </Column>
          <!-- 未寄出 -->
          <Column
            v-if="fieldFilter[9].selected"
            field="unShippedQuantity"
            header="未寄出"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <span
                v-if="tableAreaLoading"
                class="spinner-border spinner-border-sm"
              />
              <p v-else>{{ data.unShippedQuantity }}</p>
            </template>
          </Column>
          <!-- 購物金 -->
          <Column
            v-if="fieldFilter[10].selected"
            field="wallet"
            header="購物金"
            style="min-width: 60px"
            sortable
          >
            <template #body="{ data }">
              <p
                class="pointer can-click"
                @click="showModal('updateWallet', data)"
              >
                {{ data.wallet }}
              </p>
            </template>
          </Column>
          <!-- 未結單金額 -->
          <Column
            v-if="fieldFilter[11].selected"
            field="unCheckOutMoney"
            header="未結單金額"
            style="min-width: 90px; max-width: 90px"
            sortable
          >
            <template #body="{ data }">
              <span
                v-if="tableAreaLoading"
                class="spinner-border spinner-border-sm"
              />
              <p v-else>{{ $methods.numberToFixed(data.unCheckOutMoney) }}</p>
            </template>
          </Column>
          <!-- 區間金額 -->
          <Column
            v-if="fieldFilter[12].selected"
            field=""
            header="區間金額"
            style="min-width: 120px; max-width: 120px"
          >
            <template #body="{ data }">
              <span
                v-if="tableAreaLoading"
                class="spinner-border spinner-border-sm"
              />
              <span v-else>
                <p>訂單：{{ $methods.numberToFixed(data.moneyRangeOrder) }}</p>
                <p>帳單：{{ $methods.numberToFixed(data.moneyRangeBill) }}</p>
              </span>
            </template>
          </Column>
          <!-- 時間 -->
          <Column
            v-if="fieldFilter[13].selected"
            field="lastLogin"
            header="時間"
            sortable
            style="min-width: 245px"
          >
            <template #body="{ data }">
              <p class="mb-2">最後登入：{{ data.lastLogin }}</p>
              <p>加入時間：{{ data.verifyDate }}</p>
            </template>
          </Column>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- 新增虛擬顧客 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="virtualCustomerModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增虛擬顧客</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="createVirtualCustomer"
              placeholder="輸入虛擬顧客"
              maxlength="15"
              v-model="name"
            />
            <label for="createVirtualCustomer">輸入虛擬顧客(15字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="createVirtualUser"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 合併顧客 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="mergeParticipantModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            <span v-if="mergeParticipantStatus === 'p_merge_v'">實體顧客 併 虛擬顧客</span>
            <span v-else-if="mergeParticipantStatus === 'v_merge_v'">虛擬顧客 併 虛擬顧客</span>
            <span v-else-if="mergeParticipantStatus === 's_merge_s'">社群顧客 併 社群顧客</span>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="fw-bolder mb-3">
            <div class="row" v-if="selectItems.length === 1">
              <!-- 主帳號 -->
              <p class="col-5 p-0 px-1 py-3 alert alert-primary text-center">
                <p>主帳號</p>
                <hr>
                <p>{{ selectItems[0].name }}</p>
              </p>
              <!-- center -->
              <p class="d-flex flex-column justify-content-center align-items-center col-2 p-0">
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rewind-fill" viewBox="0 0 16 16">
                    <path d="M8.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L8.404 7.304Z"/>
                    <path d="M.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L.404 7.304Z"/>
                  </svg>
                </p>
                <p>合併至</p>
              </p>
              <!-- 被合併帳號 -->
              <p
                class="col-5 p-0 px-1 py-3 alert text-center"
                :class="{
                  'alert-success': mergePhysicalUserChoose.name,
                  'alert-danger': !mergePhysicalUserChoose.name
                }"
              >
                <p>被合併帳號</p>
                <p class="text-danger mt-2" v-if="mergeParticipantStatus !== 's_merge_s'">(合併後此帳號將會被刪除)</p>
                <hr>
                <p>
                  <span v-if="mergePhysicalUserChoose.name">{{ mergePhysicalUserChoose.name }}</span>
                  <span v-else>未選擇</span>
                </p>
              </p>
            </div>
          </div>
          <p class="fw-bolder mb-3 ms-1">
            選擇被合併帳號 <i class="bi bi-arrow-down-square-fill"></i>
          </p>
          <!-- 搜尋 -->
          <input
            type="text"
            class="form-control mb-3"
            placeholder="輸入搜尋值"
            v-model="mergePhysicalUserSearch"
          />
          <!-- 顧客列表 -->
          <div
            class="table-responsive"
            style="height: 350px; overflow-y: scroll"
          >
            <table class="table table-hover">
              <thead class="float-header">
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>顧客姓名</th>
                  <th>暱稱</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="physicalUser in serchPhysicalUserData"
                  :key="physicalUser.id"
                >
                  <td>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="physicalUserChoose"
                        :value="physicalUser"
                        v-model="mergePhysicalUserChoose"
                      />
                    </div>
                  </td>
                  <td>{{ physicalUser.id }}</td>
                  <td>
                    (
                    <span
                      class="text-success"
                      v-if="physicalUser.userType === 'PHYSICALUSER'"
                      >實體</span
                    >
                    <span class="text-danger" v-else>虛擬</span>
                    )
                    {{ physicalUser.name }}
                  </td>
                  <td>{{ physicalUser.nickName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="mergeParticipant"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯暱稱 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editNickNameModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯暱稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditNick"
              placeholder="輸入暱稱"
              maxlength="15"
              v-model="name"
            />
            <label for="EditNick">輸入暱稱(15字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editNickName"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯名稱 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editNameModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯名稱</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="EditName"
              placeholder="輸入名稱"
              maxlength="15"
              v-model="name"
            />
            <label for="EditName">輸入名稱(15字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editName()"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯顧客群組 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editCustomerGroupModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">編輯顧客群組</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AreaLoading v-if="customerGroups.loading"></AreaLoading>
          <select
            class="form-select mb-3"
            id="setCustomerGroup"
            v-model="selectCustomerGroupId"
            v-else
          >
            <option value="0">取消顧客群組</option>
            <option
              :value="data.id"
              v-for="data in customerGroups.data"
              :key="data.id"
            >
              {{ data.name }}
            </option>
          </select>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setCustomerGroup"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 設定VIP等級 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setVipModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">設定VIP等級</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos
            :propsSelectInfos="propsSelectInfos"
            v-if="isBatch"
          ></SelectInfos>
          <AreaLoading v-if="storeVips.loading"></AreaLoading>
          <select
            class="form-select mb-3"
            id="setVipGrate"
            v-model="selectVip"
            v-else
          >
            <option value="0">取消VIP等級</option>
            <template v-for="(data, index) in storeVips.data" :key="data.id">
              <option :value="data.id" v-if="data.id <= storeInfo.storeVipId">
                LV{{ index + 1 }}: {{ data.name }}
              </option>
            </template>
          </select>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="setVip">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 禁止/開放個人賣場 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="setForbiddenModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            禁止/開放個人賣場
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <button class="tw-btn tw-btn-danger me-2" @click="setForbidden(true)">
            禁止
          </button>
          <button class="tw-btn tw-btn-success" @click="setForbidden(false)">
            開放
          </button>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary me-2"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 審核 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="verifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">審核</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          審核此位顧客?
          <button class="tw-btn tw-btn-danger me-2" @click="setVerify(false)">
            不通過
          </button>
          <button class="tw-btn tw-btn-success" @click="setVerify(true)">
            通過
          </button>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary me-2"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增購物金 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateWalletModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增購物金</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 金額調整 -->
          <div class="form-floating mb-3">
            <input
              onwheel="this.blur()"
              type="number"
              class="form-control"
              id="UpdateWallet"
              placeholder="金額調整"
              v-model="updateWalletData.adjustment"
              @change="
                updateWalletData.adjustment = $methods.numberToFixed(
                  updateWalletData.adjustment
                )
              "
            />
            <label for="UpdateWallet">金額調整</label>
          </div>
          <!-- 備註 -->
          <div class="mb-3">
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                placeholder="備註"
                id="batchAddWalletNote"
                style="height: 150px"
                maxlength="300"
                v-model="updateWalletData.note"
              ></textarea>
              <label for="batchAddWalletNote">備註(300字)</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateWallet"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 批次結單 modal -->
  <BatchCheckOut
    :propsSelectInfos="propsSelectInfos"
    :showBatchCheckOutModal="showBatchCheckOutModal"
    @notifyParentFromBatchCheckOut="notifyParentFromBatchCheckOut"
  ></BatchCheckOut>
  <!-- 批次轉換VIP身分 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchSetVipModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">批次轉換VIP身分</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-danger mb-3">*此步驟會將符合條件的顧客全部進行轉換</p>
          <AreaLoading v-if="storeVips.loading"></AreaLoading>
          <div v-else>
            <span class="me-2">從</span>
            <!-- 轉換前VIP身分 -->
            <select
              class="form-select w-25 d-inline-block me-2"
              id="bBatchSetVipGrate"
              v-model="batchOperate.before"
            >
              <option value="" disabled selected>選擇VIP身分</option>
              <option value="null">非VIP身分</option>
              <template v-for="(data, index) in storeVips.data" :key="data.id">
                <option :value="data.id" v-if="data.id <= storeInfo.storeVipId">
                  LV{{ index + 1 }}: {{ data.name }}
                </option>
              </template>
            </select>
            <span class="me-2">轉換成</span>
            <!-- 轉換後VIP身分 -->
            <select
              class="form-select w-25 d-inline-block me-2"
              id="aBatchSetVipGrate"
              v-model="batchOperate.after"
            >
              <option value="" disabled selected>選擇VIP等級</option>
              <option value="0">取消VIP等級</option>
              <template v-for="(data, index) in storeVips.data" :key="data.id">
                <option :value="data.id" v-if="data.id <= storeInfo.storeVipId">
                  LV{{ index + 1 }}: {{ data.name }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchSetVip"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 批次轉換顧客群組 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="batchSetCustomerGroupModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            批次轉換顧客群組
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-danger mb-3">*此步驟會將符合條件的顧客全部進行轉換</p>
          <AreaLoading v-if="customerGroups.loading"></AreaLoading>
          <div v-else>
            <span class="me-2">從</span>
            <select
              class="form-select w-25 d-inline-block me-2"
              id="bBatchsetCustomerGroup"
              v-model="batchOperate.before"
            >
              <option value="" disabled selected>選擇顧客群組</option>
              <option value="null">非顧客群組</option>
              <option
                :value="data.id"
                v-for="data in customerGroups.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
            <span class="me-2">轉換成</span>
            <select
              class="form-select w-25 d-inline-block me-2"
              id="aBatchsetCustomerGroup"
              v-model="batchOperate.after"
            >
              <option value="" disabled selected>選擇顧客群組</option>
              <option value="0">取消顧客群組</option>
              <option
                :value="data.id"
                v-for="data in customerGroups.data"
                :key="data.id"
              >
                {{ data.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="batchSetCustomerGroup"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 匯出 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="exportExcelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">匯出 Excel</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>是否將所選擇到的訂單匯出成 Excel 格式 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bolder
            "
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-danger">*確認刪除這些顧客</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setDeleted(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 復原 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="restoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-primary">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-primary
              text-primary
              fw-bolder
            "
          >
            復原
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="text-primary">是否確定恢復這些顧客?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="setDeleted(false)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- LineNotify 通知 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="notifyModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-if="notifyStatus === 'general'"
          >
            Line Notify通知 (群發)
          </h5>
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-else-if="notifyStatus === 'groupA'"
          >
            Line Notify通知 (群發：已配單但未結單)
          </h5>
          <h5
            class="modal-title tw-border-start fw-bolder"
            v-else-if="notifyStatus === 'groupB'"
          >
            Line Notify通知 (群發：已結單但未付款)
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <!-- 提醒訊息 -->
          <div>
            <p class="mb-2">
              系統將會發送訊息給所有<span class="text-success fw-bolder"
                >有啟動LINE Notify</span
              >的顧客
            </p>
            <p class="mb-3">為避免混淆，發送的訊息皆會註明是由賣家發出</p>
            <!-- 只有(已配單但未結單、已結單但未付款)有 -->
            <div v-if="notifyStatus !== 'general'">
              <p class="mb-3">
                將會發送訊息給所有
                <span
                  class="text-danger fw-bolder"
                  v-if="notifyStatus === 'groupA'"
                  >已配單但未結單</span
                >
                <span
                  class="text-danger fw-bolder"
                  v-else-if="notifyStatus === 'groupB'"
                  >已結單但未付款</span
                >
                的顧客
              </p>
              <div class="form-check p-0">
                <input
                  class="form-check-input m-0 me-2"
                  type="checkbox"
                  v-model="checkIsSetOwnNotifyMsg"
                  id="checkIsSetOwnNotifyMsg"
                />
                <label class="form-check-label" for="checkIsSetOwnNotifyMsg">
                  選擇自行輸入內容
                </label>
              </div>
            </div>
          </div>
          <!-- 備註(兩種型態) -->
          <div class="mb-3">
            <!-- 第一種:一般型態 -->
            <div v-if="checkIsSetOwnNotifyMsg">
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  placeholder="備註"
                  id="batchAddWalletNote"
                  style="height: 150px"
                  maxlength="300"
                  v-model="notifyMsg"
                ></textarea>
                <label for="batchAddWalletNote">備註(300字)</label>
              </div>
            </div>
            <!-- 第二種:(已配單但未結單、已結單但未付款) 勾選「選擇自行輸入內容」 -->
            <div class="tw-container border border-1 m-0" v-else>
              <p class="fw-bolder">
                系統預設訊息：
                <span class="text-secondary" v-if="notifyStatus === 'groupA'"
                  >XX您好，您有已配單但未結單</span
                >
                <span
                  class="text-secondary"
                  v-else-if="notifyStatus === 'groupB'"
                  >XX您好，您有已結單但未付款</span
                >
              </p>
            </div>
          </div>
          <!-- 上傳圖片 -->
          <div class="mb-3">
            <div class="input-group mb-3">
              <input
                type="file"
                class="form-control"
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="sendNotify"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog" :style="scrollWidth">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/participant.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="alert alert-danger">
            以下兩種時間區間皆會影響表格中"未配"，"已配未結"，"未付款"，"未寄出"，"未結單金額"與"區間金額"
            !
          </p>
          <!-- 帳單區間金額(有付款) -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_BillTimeSwitch"
                v-model="advancedSearchData.billTimeRange.switch"
              />
              <label class="form-check-label" for="AS_BillTimeSwitch"
                >帳單區間金額(有付款)</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.billTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_BillStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.billTimeRange.startTime"
                  />
                  <label for="AS_BillStartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_BillEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.billTimeRange.endTime"
                  />
                  <label for="AS_BillEndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 訂單區間金額 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_OrderTimeSwitch"
                v-model="advancedSearchData.orderTimeRange.switch"
              />
              <label class="form-check-label" for="AS_OrderTimeSwitch"
                >訂單區間金額</label
              >
            </div>
            <!-- 時間 -->
            <div
              class="row mb-3"
              v-if="advancedSearchData.orderTimeRange.switch"
            >
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_OrderStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.orderTimeRange.startTime"
                  />
                  <label for="AS_OrderStartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_OrderEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.orderTimeRange.endTime"
                  />
                  <label for="AS_OrderEndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 顧客群組 -->
          <div class="mb-3">
            <div class="form-floating" v-if="!customerGroups.loading">
              <select
                class="form-select"
                id="AS_Supplier"
                v-model="advancedSearchData.customerGroup"
              >
                <option :value="null">不選擇</option>
                <option
                  v-for="data in customerGroups.data"
                  :value="data"
                  :key="data.id"
                >
                  {{ data.name }}
                </option>
              </select>
              <label for="AS_Supplier">顧客群組</label>
            </div>
            <AreaLoading v-else></AreaLoading>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                  <div
                    class="form-floating mt-3"
                    v-if="data.id === 'searchByContent'"
                  >
                    <input
                      type="text"
                      class="form-control"
                      :id="`SearchText${data.id}`"
                      maxlength="100"
                      placeholder="輸入內容"
                      v-model="advancedSearchData.searchContentText"
                    />
                    <label :for="`SearchText${data.id}`">輸入內容(100字)</label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// mixin
import { HandleImages } from '../../../methods/mixins/handleImages'
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// components
import BatchCheckOut from '../../../components/modal/BatchCheckOut.vue'
import FieldFilter from '../../../components/tools/FieldFilter.vue'

export default {
  components: {DataTable, Column, ColumnGroup, Row, BatchCheckOut, FieldFilter},
  data() {
    return {
      // modal
      virtualCustomerModal: {},
      mergeParticipantModal: {},
      editNickNameModal: {},
      editNameModal: {},
      editCustomerGroupModal: {},
      setVipModal: {},
      setForbiddenModal: {},
      verifyModal: {},
      updateWalletModal: {},
      batchSetVipModal: {},
      batchSetCustomerGroupModal: {},
      exportExcelModal: {},
      delModal: {},
      restoreModal: {},
      notifyModal: {},
      introductionModal: {},
      advancedSearchModal: {},
      //data
      serverToken: '',
      storeId: 0,
      // 開啟批次結單
      showBatchCheckOutModal: false,
      name: '',
      selectVip: 0,
      selectCustomerGroupId: 0,
      updateWalletData: {
        adjustment: 0,
        note: ''
      },
      batchOperate: {
        before: '',
        after: ''
      },
      participantsData: [],
      serchPhysicalUserData: [],
      mergePhysicalUserSearch: null,
      mergePhysicalUserChoose: {},
      // 發送 notify
      notifyMsg: '',
      notifyStatus: '',
      checkIsSetOwnNotifyMsg: false,
      notifyOtherData: null, // 用來記錄訂單愈帳單詳細資訊
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      tableAreaLoading: false,
      participantList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 選擇到的物件
      selectItems: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      // 操作方式(批次:true、單選:false)
      isBatch: false,
      // 賣場資訊
      storeInfo: {},
      // storeVips
      storeVips: {
        loading: false,
        data: []
      },
      // 顧客群組資訊
      customerGroups: {
        loading: false,
        data: []
      },
      // 合併顧客狀態
      mergeParticipantStatus: 'p_merge_v',
      // 進階搜尋
      advancedSearchData: {
        searchContentText: '',
        billTimeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        orderTimeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        customerGroup: null,
        singleSelect: {
          participantStatus: '審核通過且未被刪除',
          allocatedAndCheckout: '不選擇',
          uncheckoutStatus: '不選擇',
          walletStatus: '不選擇',
          vipStatus: '不選擇',
          participantHabitA: '不選擇',
          participantHabitB: '不選擇',
          searchByContent: '不選擇',
          mergeStatus: '顯示全部',
          arrivalStatus: '不選擇',
        }
      },
      recordAdvancedSearchData: {},
      updateStatus: 'first', // 判斷是否要進階搜尋，'first':頁面第一次載入，需跑進階搜尋 ； 'renew':特定幾筆資料更新，不跑進階搜尋。
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  mixins: [HandleImages],
  created() {
    this.initialization()
    this.getPrefer()
    this.getStoreInfo()
  },
  mounted() {
    this.createModals([
      'editNickNameModal','editNameModal','editCustomerGroupModal','setVipModal','setForbiddenModal','verifyModal'
      ,'updateWalletModal','virtualCustomerModal','exportExcelModal','delModal','restoreModal','batchSetVipModal'
      ,'batchSetCustomerGroupModal','mergeParticipantModal','notifyModal','introductionModal',
      'advancedSearchModal'
    ])
    this.changeTableHeigth()
    // 監聽視窗異動
    window.addEventListener("resize", this.changeTableHeigth)
  },
  unmounted() {
    // 移除監聽視窗異動
    window.removeEventListener("resize", this.changeTableHeigth)
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-participant-local')
  },
  watch: {
    mergePhysicalUserSearch: {
      handler(val) {
        this.serchPhysicalUserData = this.participantsData
        if (val !== '') {
          this.serchPhysicalUserData = this.serchPhysicalUserData.filter(item => {
            const mergeStr = `${item.id},${item.name},${item.nickName}`
            return mergeStr.match(val)
          })
        }
      }
    }
  },
  computed: {
    // 偏好 > 表格預設顯示筆數
    D4Row() {
      let D4Row = 20
      this.$store.state.Prefer.p_participant.rowCount.forEach(item => {
        if (item.selected) D4Row = item.value
      })
      return parseInt(D4Row)
    },
    // 偏好 > 進階搜尋預設時間區間
    timeRange() {
      return parseInt(this.$store.state.Prefer.p_participant.searchTimeRange[0].value)
    },
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_participant.fieldFilter
    },
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      // 介紹表格可點選區寬度
      this.scrollWidth = `max-width: ${window.innerWidth - 20}px`
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-participant-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'participantStatus',
          key: '顧客狀態',
          value: [
            {
              id: 'A1',
              value: '審核通過且未被刪除'
            },
            {
              id: 'A2',
              value: '已刪除'
            },
            {
              id: 'A3',
              value: '審核未通過'
            },
            {
              id: 'A4',
              value: '尚未審核'
            },
            {
              id: 'A5',
              value: '所有'
            },
          ]
        },
        {
          id: 'allocatedAndCheckout',
          key: '配單/結單',
          value: [
            {
              id: 'B1',
              value: '不選擇'
            },
            {
              id: 'B2',
              value: '已完成配單與結單'
            },
            {
              id: 'B3',
              value: '未配單 > 0 且 已配未結 > 0'
            },
            {
              id: 'B4',
              value: '未配單 > 0'
            },
            {
              id: 'B5',
              value: '已配未結 > 0'
            },
          ]
        },
        {
          id: 'uncheckoutStatus',
          key: '未結單金額狀態',
          value: [
            {
              id: 'C1',
              value: '不選擇'
            },
            {
              id: 'C2',
              value: '未結單金額 > 0'
            }
          ]
        },
        {
          id: 'walletStatus',
          key: '購物金餘額',
          value: [
            {
              id: 'D1',
              value: '不選擇'
            },
            {
              id: 'D2',
              value: '> 0'
            },
            {
              id: 'D3',
              value: '= 0'
            }
          ]
        },
        {
          id: 'vipStatus',
          key: '顧客 VIP',
          value: [
            {
              id: 'E1',
              value: '不選擇'
            },
            {
              id: 'E2',
              value: '是 VIP'
            },
            {
              id: 'E2',
              value: '非 VIP'
            }
          ]
        },
        {
          id: 'participantHabitA',
          key: '顧客習性 1',
          value: [
            {
              id: 'F1',
              value: '不選擇'
            },
            {
              id: 'F2',
              value: '全部到貨但還尚有未結單的顧客'
            }
          ]
        },
        {
          id: 'participantHabitB',
          key: '顧客習性 2',
          value: [
            {
              id: 'G1',
              value: '不選擇'
            },
            {
              id: 'G2',
              value: '購物金不夠支付未結單的金額'
            }
          ]
        },
        {
          id: 'searchByContent',
          key: '依內容搜尋',
          value: [
            {
              id: 'H1',
              value: '不選擇'
            },
            {
              id: 'H2',
              value: '依顧客名稱搜尋'
            },
            {
              id: 'H3',
              value: '依顧客暱稱搜尋'
            }
          ]
        },
        {
          id: 'mergeStatus',
          key: '合併狀態',
          value: [
            {
              id: 'I1',
              value: '顯示全部'
            },
            {
              id: 'I2',
              value: '僅顯示主帳號'
            },
            {
              id: 'I3',
              value: '僅顯示被合併帳號'
            }
          ]
        },
        {
          id: 'arrivalStatus',
          key: '到貨狀態',
          value: [
            {
              id: 'J1',
              value: '不選擇'
            },
            {
              id: 'J2',
              value: '全到貨'
            }
          ]
        }
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // dataTable 內容高度計算
    changeTableHeigth() {
      // 紀錄 dataTabel 高度 (40px: 最上面 marginTop, 170px: 表格中除了內容其餘多的)
      this.scrollHeight = window.innerHeight - document.getElementById('ParticipantTopArea').clientHeight - 160
    },
    // 開啟 modal
    showModal(status, item) {
      this.isBatch = false
      if (status === 'editNickName') {
        this.getSelectInfo(item)
        this.name = item.nickName
        this.editNickNameModal.show()
      } else if (status === 'editName') {
        this.getSelectInfo(item)
        this.name = item.name
        this.editNameModal.show()
      } else if (status === 'editCustomerGroup') {
        this.getSelectInfo(item)
        this.selectCustomerGroupId = item.customerGroupId ? item.customerGroupId : 0
        this.editCustomerGroupModal.show()
      } else if (status === 'setVip') {
        // 批次
        if(!item) {
          this.isBatch = true
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
          this.propsSelectInfos.data = this.selectItems
          this.selectVip = 0
        } 
        // 單項
        else {
          this.getSelectInfo(item)
          this.selectVip = item.storeVipId ? item.storeVipId : 0
        }
        this.setVipModal.show()
      } else if (status === 'setForbidden') {
        this.getSelectInfo(item)
        this.setForbiddenModal.show()
      } else if (status === 'mergeParticipant') {
        // 合併顧客
        // 紀錄合併狀態
        this.mergeParticipantStatus = item
        // 判斷是否有誤
        const errorMessage = this.handleMergeParticipantError()
        if (errorMessage) return this.SweetAlert('other', errorMessage)
        // 依照合併狀態去過濾被合併顧客列表
        this.filterParticipants()
        this.serchPhysicalUserData = this.participantsData
        this.mergePhysicalUserSearch = ''
        this.mergePhysicalUserChoose = {}
        this.mergeParticipantModal.show()
      } else if (status === 'virtualCustomer') {
        this.name = ''
        this.virtualCustomerModal.show()
      } else if (status === 'verify') {
        // 批次
        if(!item) {
          this.isBatch = true
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
          this.propsSelectInfos.data = this.selectItems
        }
        // 單項
        else {
          this.getSelectInfo(item)
        }
        // 判斷是否選到已審核通過的顧客
        let isVerify = false
        this.selectItems.some(item => {
          if (item.verify === true) return isVerify = true
        })
        if (isVerify) return this.SweetAlert('other', '有選到已審核通過的顧客')
        this.verifyModal.show()
      } else if (status === 'updateWallet') {
        this.updateWalletData = {
          adjustment: 0,
          note: ''
        }
        // 批次
        if(!item) {
          this.isBatch = true
          if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
          this.propsSelectInfos.data = this.selectItems
        }
        // 單項
        else {
          this.getSelectInfo(item)
        }
        this.updateWalletModal.show()
      } else if (status === 'batchCheckOut') {
        // 批次結單
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        // 傳遞到子層開啟 modal
        this.showBatchCheckOutModal = true
      } else if (status === 'batchSetVip') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        this.batchOperate = {
          before: '',
          after: ''
        }
        this.batchSetVipModal.show()
      } else if (status === 'batchSetCustomerGroup') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        this.batchOperate = {
          before: '',
          after: ''
        }
        this.batchSetCustomerGroupModal.show()
      } else if (status === 'exportExcel') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'del') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        // 判斷是否選到已被刪除的顧客
        let isDeleted = false
        this.selectItems.some(item => {
          if (item.deleted) return isDeleted = true
        })
        if (isDeleted) return this.SweetAlert('other', '有選到已被刪除的顧客')
        this.delModal.show()
      } else if (status === 'restore') {
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.propsSelectInfos.data = this.selectItems
        // 判斷是否選到未被刪除的顧客
        let isRestore = false
        this.selectItems.some(item => {
          if (!item.deleted) return isRestore = true
        })
        if (isRestore) return this.SweetAlert('other', '有選到未被刪除的顧客')
        this.restoreModal.show()
      } else if (status === 'lineNotifyGeneral') {
        // 一般
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.selectItems = this.selectItems.filter(item => {
          if(item.verify && !item.deleted && item.userLineLink.notifyAccessToken) return item
        })
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請選擇<br><span class="fw-bolder text-danger">有綁定Line Notify</span> 且 <span class="fw-bolder text-danger">未被刪除</span> 且 <span class="fw-bolder text-danger">審核通過</span><br>的顧客')
        this.propsSelectInfos.data = this.selectItems
        this.notifyMsg = ''
        this.notifyStatus = 'general'
        this.checkIsSetOwnNotifyMsg = true
        document.querySelector("input[type=file]").value = ''
        this.notifyModal.show()
      } else if (status === 'lineNotifyGroupA') {
        // 群發:已配單但未結單
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.selectItems = this.selectItems.filter(item => {
          return item.allocatedButUnCheckOutQuantity > 0 && item.verify && !item.deleted && item.userLineLink.notifyAccessToken
        })
        if (this.selectItems.length === 0) return this.SweetAlert('other', '您所選的顧客皆可能"未符合已配未結條件"，也可能顧客本身"尚未通過審核"或是"已被刪除"或是"未綁定 Line Notify"')
        this.propsSelectInfos.data = this.selectItems
        this.notifyStatus = 'groupA'
        this.$methods.switchLoading('show')
        this.getNotitfyOtherInfo().then(res => {
          console.log(res)
          if (res.code === "200") {
            const merchorders = res.data[0].objects
            this.notifyOtherData = null
            this.notifyOtherData = new Map()
            merchorders.forEach(item => { this.sortoutNotifyOtherData(item) })
            console.log(this.notifyOtherData)
            this.notifyMsg = ''
            this.checkIsSetOwnNotifyMsg = false
            document.querySelector("input[type=file]").value = ''
            this.notifyModal.show()
          }
          this.$methods.switchLoading('hide')
        }).catch(err => {
          console.log(err)
          this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
        })
      } else if (status === 'lineNotifyGroupB') {
        // 群發:已結單但未付款
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一位顧客')
        this.selectItems = this.selectItems.filter(item => {
          return item.unPaidQuantity > 0 && item.verify && !item.deleted && item.userLineLink.notifyAccessToken
        })
        if (this.selectItems.length === 0) return this.SweetAlert('other', '您所選的顧客皆可能"未符合已結單但未付款"，也可能顧客本身"尚未通過審核"或是"已被刪除"或是"未綁定 Line Notify"')
        this.propsSelectInfos.data = this.selectItems
        this.$methods.switchLoading('show')
        this.notifyStatus = 'groupB'
        this.getNotitfyOtherInfo().then(res => {
          console.log(res)
          if (res.code === "200") {
            const bills = res.data[0].objects
            this.notifyOtherData = null
            this.notifyOtherData = new Map()
            let merchorders = []
            bills.forEach(bill => {
              bill.billBodies.forEach(body => {
                body.merchOrder.buyerId = bill.buyerId
                merchorders.push(body.merchOrder)
              })
            })
            merchorders.forEach(item => { this.sortoutNotifyOtherData(item) })
            this.notifyMsg = ''
            this.checkIsSetOwnNotifyMsg = false
            document.querySelector("input[type=file]").value = ''
            this.notifyModal.show()
          }
          this.$methods.switchLoading('hide')
        }).catch(err => {
          console.log(err)
          this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          this.$methods.switchLoading('hide')
        })
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 取得賣場資訊
    getStoreInfo() {
      const vm = this
      const getStoreInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 5,
          ids: [this.storeId],
          methods: '{getStoreVips,getCustomerGroups}'
        }
      ]
      this.storeInfo = {}
      // storeVips
      this.storeVips = {
        loading: true,
        data: []
      },
      // 顧客群組資訊
      this.customerGroups = {
        loading: true,
        data: []
      },
      $.ajax({
        type: 'POST',
        async: true,
        url: getStoreInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const storeInfo = res.data[0].objects[0]
            vm.storeInfo = storeInfo
            // storeVips
            vm.storeVips = {
              loading: false,
              data: storeInfo.storeVips
            }
            // 顧客群組資訊
            vm.customerGroups = {
              loading: false,
              data: storeInfo.customerGroups
            }
            // 先取得預設時間區間
            vm.computedD4TimeRange()
            vm.advancedSearch()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // * 更新資料 (start)
    /**
     * 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal) {
      this.$methods.switchLoading('show')
      let data = []
      let ids = []
      this.selectItems.forEach(participant => { ids.push(participant.id) })
      switch (updateItem) {
        case 'participant':
          data = [{
            ids: ids,
            type: 13,
            methods: '{}'
          }]
          break;
        case 'storeVip':
          data = [{
            ids: ids,
            type: 13,
            methods: '{getStoreVip}'
          }]
          break;
        case 'customerGroup':
          data = [{
            ids: ids,
            type: 13,
            methods: '{getCustomerGroup}'
          }]
          break;
        case 'user':
          data = [{
            ids: ids,
            type: 13,
            methods: '{getUser}'
          }]
          break;
        case 'mergeParticipant':
          data = [{
            ids: ids,
            type: 13,
            methods: '{getUser{getUserLineLink,getUserFacebookLink,isPossibleToMerge},getCustomerGroup,getStoreVip}'
          }]
          break;
        case 'getAll':
          data = [{
            ids: ids,
            type: 13,
            methods: '{getUser{getUserLineLink,getUserFacebookLink,isPossibleToMerge},getCustomerGroup,getStoreVip}'
          }]
          break;
        default:
          throw new Error('Participant.vue > updateData > updateItem 未處理')
      }
      // 取要更新的資料
      const updateData = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      };
      const vm = this
      $.ajax({
        type: "POST",
        async: true,
        url: updateData,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const participants = res.data[0].objects
            vm.sortoutUpdateData(participants, updateItem, modal)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 整理更新資料
    sortoutUpdateData(participants, updateItem, modal) {
      this.updateStatus = 'renew' // 指定更新狀態為'renew'，使帳單與訂單資料更新時不會跑進階搜尋
      let updateObj
      let ids = [] 
      this.participantList.forEach((origin, originIndex) => {
        participants.forEach((update, updateIndex) => {
          if (origin.id === update.id) {
            switch (updateItem) {
              case 'participant':
                origin.nickName = update.nickName
                origin.forbidden = update.forbidden
                origin.verify = update.verify
                origin.wallet = update.wallet
                origin.deleted = update.deleted
                break;
              case 'storeVip':
                origin.storeVipId = update.storeVipId
                origin.storeVip = update.storeVip
                break;
              case 'customerGroup':
                origin.customerGroupId = update.customerGroupId
                origin.customerGroup = update.customerGroup
                break;
              case 'user':
                origin.name = update.user.name
                break;
              case 'mergeParticipant':
                updateObj = this.handleParticipants(update)
                this.participantList[originIndex] = JSON.parse(JSON.stringify(updateObj))
                this.getOrdersAndBillsQuery([update.id])
                if (modal === 'mergeParticipantModal') this.handleMergeParticipants()
                break;
              case 'getAll':
                ids.push(update.id)
                if (ids.length === participants.length) this.getOrdersAndBillsQuery(ids)
                break;
              default:
                throw new Error('Participants.vue > sortoutUpdateData > updateItem 未處理')
            }
          }
        })
      })
      // 新增
      switch (modal) {
        case 'virtualCustomerModal':
          updateObj = this.handleParticipants(participants[0])
          this.participantList.push(updateObj)
          break;
      }

      this.SweetAlert('200')
      if (modal) {
        this[modal].hide()
      }
      this.selectItems = []
      this.$methods.switchLoading('hide')
    },
    // 合併帳號 > 更新處理
    handleMergeParticipants() {
      if (this.mergeParticipantStatus === 'p_merge_v' || this.mergeParticipantStatus === 'v_merge_v') {
        // 刪除被合併帳號
        this.participantList.some((item, index) => {
          if (item.id === this.mergePhysicalUserChoose.id) {
            this.participantList.splice(index, 1)
            return true
          }
        })
      }
    },
    // * 更新資料 (end)
    // * 取顧客 (start)
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.billTimeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.billTimeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
      this.advancedSearchData.orderTimeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.orderTimeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
    },
    // 常用搜尋
    frequentlySearched(searchStatus) {
      // 紀錄要變更的單選項目
      let singleSelectOption = null
      // 還原所有單選選項預設值
      this.advancedSearchData.singleSelect.participantStatus = '審核通過且未被刪除'
      this.advancedSearchData.singleSelect.allocatedAndCheckout = '不選擇'
      this.advancedSearchData.singleSelect.arrivalStatus = '不選擇'
      // 判斷是哪個單選項目要變更
      if (searchStatus === '已刪除' || searchStatus === '審核未通過' || searchStatus === '尚未審核') singleSelectOption = 'participantStatus'
      else if (searchStatus === '已配未結 > 0') singleSelectOption = 'allocatedAndCheckout'
      else if (searchStatus === '全到貨') singleSelectOption = 'arrivalStatus'
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!singleSelectOption) return alert('沒有此搜尋選項')
      this.advancedSearchData.singleSelect[singleSelectOption] = searchStatus
      this.advancedSearch()
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.updateStatus = 'first' // 跑後續帳單與訂單資料的進階搜尋
      if (this.advancedSearchData.singleSelect.searchByContent !== '不選擇' && !this.advancedSearchData.searchContentText) return this.SweetAlert('other', '請輸入搜尋內容')
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.selectItems = []
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      const query = {"columns":[{"columnName":"id","siteObjectType":13}],"distinct":true,"group":{"groups":[{"column":{"columnName":"StoreId","siteObjectType":13},"operator":{"operator":"=","type":1,"value":this.storeId}}],"operator":1},"siteObjectType":13,"sqlCommandType":1}
      // 顧客狀態
      switch(this.advancedSearchData.singleSelect.participantStatus) {
        case '審核通過且未被刪除':
          query.group.groups.push({
            column: {
              columnName: "verify",
              siteObjectType: 13
            },
            operator: {
              operator: "=",
              type: 1,
              value: true
            }
          })
          query.group.groups.push({
            column: {
              columnName: "deleted",
              siteObjectType: 13
            },
            operator: {
              operator: "=",
              type: 1,
              value: false
            }
          })
          break;
        case '已刪除':
          query.group.groups.push({
            column: {
              columnName: "deleted",
              siteObjectType: 13
            },
            operator: {
              operator: "=",
              type: 1,
              value: true
            }
          })
          break;
        case '審核未通過':
          query.group.groups.push({
            column: {
              columnName: "verify",
              siteObjectType: 13
            },
            operator: {
              operator: "=",
              type: 1,
              value: false
            }
          })
          break;
        case '尚未審核':
          query.group.groups.push({
            column: {
              columnName: "verify",
              siteObjectType: 13
            },
            operator: {
              operator: "true",
              type: 4,
            }
          })
          break;
      }
      // 購物金餘額
      switch(this.advancedSearchData.singleSelect.walletStatus) {
        case '> 0':
          query.group.groups.push({
            column: {
              columnName: "wallet",
              siteObjectType: 13
            },
            operator: {
              operator: ">",
              type: 1,
              value: 0
            }
          })
          break;
        case '= 0':
          query.group.groups.push({
            column: {
              columnName: "wallet",
              siteObjectType: 13
            },
            operator: {
              operator: "=",
              type: 1,
              value: 0
            }
          })
          break;
      }
      // 顧客 VIP
      switch(this.advancedSearchData.singleSelect.vipStatus) {
        case '是 VIP':
          query.group.groups.push({
            column: {
              columnName: "storeVipId",
              siteObjectType: 13
            },
            operator: {
              operator: ">",
              type: 1,
              value: 0
            }
          })
          break;
        case '非 VIP':
          query.group.groups.push({
            column: {
              columnName: "storeVipId",
              siteObjectType: 13
            },
            operator: {
              operator: "true",
              type: 4,
            }
          })
          break;
      }
      // 顧客群組
      if (this.advancedSearchData.customerGroup) {
        query.group.groups.push({
          column: {
            columnName: "customerGroupId",
            siteObjectType: 13
          },
          operator: {
            operator: "=",
            type: 1,
            value: this.advancedSearchData.customerGroup.id
          }
        })
      }
      if (closeModal) this.advancedSearchModal.hide()
      this.getParticipantList(query)
    },
    // 取得顧客資訊
    getParticipantList(query) {
      const vm = this
      const getParticipantList = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getUser{getUserLineLink,getUserFacebookLink,isPossibleToMerge},getCustomerGroup,getStoreVip}'
        }
      ]
      this.participantList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantList,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            console.log("資料抓取成功")
            const participantList = res.data[0].objects
            vm.sortoutParticipants(participantList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理顧客
    sortoutParticipants(participantList) {
      let participantIds = []
      participantList.forEach(item => {
        participantIds.push(item.id)
        const object = this.handleParticipants(item)

        // 進階搜尋 > 依內容搜尋
        let matchSearchByContent = false
        switch(this.advancedSearchData.singleSelect.searchByContent) {
          case '依顧客名稱搜尋':
            if (item.user.name.toLowerCase().match(this.advancedSearchData.searchContentText.toLowerCase())) matchSearchByContent = true
            break;
          case '依顧客暱稱搜尋':
            if (item.nickName && item.nickName.toLowerCase().match(this.advancedSearchData.searchContentText.toLowerCase())) matchSearchByContent = true
            break;
          default:
            matchSearchByContent = true
            break;
        }

        // 進階搜尋 > 合併狀態
        let matchMergeStatus = false
        switch (this.advancedSearchData.singleSelect.mergeStatus) {
          case '僅顯示主帳號':
            if (!item.user.masterId) matchMergeStatus = true
            break;
          case '僅顯示被合併帳號':
            if (item.user.masterId) matchMergeStatus = true
            break;
          default:
            matchMergeStatus = true
            break;
        }

        if (matchSearchByContent && matchMergeStatus) this.participantList.push(JSON.parse(JSON.stringify(object)))
      })
      console.log(this.participantList)
      this.$methods.switchLoading('hide')
      this.dataTabelLoading = false
      if (participantIds.length === 0) return
      this.getOrdersAndBillsQuery(participantIds)
    },
    // 特別處理顧客物件
    handleParticipants(item) {
      // 原資料
      const object = {
        allocatedButUnCheckOutQuantity: 0,
        accessCustomerPage: item.accessCustomerPage,
        accessPerformanceAndRankingPage: item.accessPerformanceAndRankingPage,
        accessProfitAndCost: item.accessProfitAndCost,
        accessSupplier: item.accessSupplier,
        authority: item.authority,
        customerGroup: item.customerGroup,
        customerGroupId: item.customerGroupId,
        deleteMerchOrder: item.deleteMerchOrder,
        deleteMerchandise: item.deleteMerchandise,
        deleteParticipant: item.deleteParticipant,
        deleted: item.deleted,
        forbidden: item.forbidden,
        id: item.id,
        lastLogin: this.$methods.moment(item.user.lastLogin).format("YYYY-MM-DD HH:mm:ss"),
        lastVisit: this.$methods.moment(item.lastVisit).format("YYYY-MM-DD HH:mm:ss"),
        modifierId: item.modifierId,
        modifyDate: item.modifyDate,
        moneyRangeBill: 0,
        moneyRangeOrder: 0,
        name: item.user.name,
        nickName: item.nickName,
        note: item.note,
        receiveLineNotify: item.receiveLineNotify,
        storeId: item.storeId,
        storeVip: item.storeVip,
        storeVipId: item.storeVipId,
        updateStoreConfig: item.updateStoreConfig,
        user: item.user,
        userFacebookLink: item.user.userFacebookLink,
        userLineLink: item.user.userLineLink,
        userId: item.userId,
        userType: item.userType,
        unAllocatedQuantity: 0,
        unCheckOutMoney: 0,
        unCheckOutQuantity: 0,
        unPaidQuantity: 0,
        unShippedQuantity: 0,
        verify: item.verify,
        verifyDate: this.$methods.moment(item.verifyDate).format("YYYY-MM-DD HH:mm:ss"),
        verifyParticipant: item.verifyParticipant,
        wallet: item.wallet,
        fbProfilePicture: null,
        lineProfilePicture: null,
        possibleToMerge: item.user.possibleToMerge,
      }
      // 處理大頭貼
      if (item.user.userLineLink && item.user.userLineLink.profilePicture) object.lineProfilePicture = item.user.userLineLink.profilePicture
      if (item.user.userFacebookLink && item.user.userFacebookLink.profilePicture) object.fbProfilePicture = item.user.userFacebookLink.profilePicture

      return object
    },
    // 取顧客訂單與帳單的 query
    getOrdersAndBillsQuery(participantIds) {
      let billQuery = {"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":27},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"buyerId","siteObjectType":27},"operator":{"type":3,"value":participantIds}}],"operator":1},"siteObjectType":27,"sqlCommandType":1}
      let merchOrderQuery = {"columns":[{"columnName":"id","siteObjectType":26}],"group":{"groups":[{"column":{"columnName":"storeId","siteObjectType":26},"operator":{"operator":"=","type":1,"value":this.storeId}},{"column":{"columnName":"buyerId","siteObjectType":26},"operator":{"type":3,"value":participantIds}}],"operator":1},"siteObjectType":26,"sqlCommandType":1}
      // 帳單區間
      if (this.advancedSearchData.billTimeRange.switch) {
        if (this.advancedSearchData.billTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.billTimeRange.startTime} 00:00:00`) / 1000
          billQuery.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 27
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.billTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.billTimeRange.endTime} 23:59:59`) / 1000
          billQuery.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 27
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      // 訂單區間
      if (this.advancedSearchData.orderTimeRange.switch) {
        if (this.advancedSearchData.orderTimeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.orderTimeRange.startTime} 00:00:00`) / 1000
          merchOrderQuery.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 26
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.orderTimeRange.endTime) {
          const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.orderTimeRange.endTime} 23:59:59`) / 1000
          merchOrderQuery.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 26
            },
            operator: {
              operator: "<=",
              type: 1,
              value: {
                name: `from_unixtime(${endTime})`
              }
            }
          })
        }
      }
      this.getOrdersAndBills(billQuery, merchOrderQuery)
    },
    // 取顧客訂單與帳單
    getOrdersAndBills(billQuery, merchOrderQuery) {
      const vm = this
      const getOrdersAndBillsApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      this.tableAreaLoading = true
      const data = [
        {
          query: merchOrderQuery,
          methods: '{getCheckOutQuantity}'
        },
        {
          query: billQuery,
          methods: '{getTotalPrice,getTopestParent}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getOrdersAndBillsApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchOrders = res.data[0].objects
            const bills = res.data[1].objects
            vm.sortOutOrdersAndBills(merchOrders, bills)
            console.log("資料抓取成功")
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理顧客訂單與帳單資料
    sortOutOrdersAndBills(merchOrders, bills) {
      setTimeout(() => {
        let participantsMap = new Map()
        const obj = {
          unAllocatedQuantity: 0,
          allocatedButUnCheckOutQuantity: 0,
          unCheckOutMoney: 0,
          unCheckOutQuantity: 0,
          moneyRangeOrder: 0,
          moneyRangeBill: 0,
          unPaidQuantity: 0,
          unShippedQuantity: 0,
          allocatedButUnCheckOutMerchOrderIds: [],
          unPaidBillIds: []
        }
        // 訂單
        merchOrders.forEach(item => {
          if (!participantsMap.has(item.buyerId)) {
            participantsMap.set(item.buyerId, JSON.parse(JSON.stringify(obj))) 
          }
          console.log(item)
          participantsMap.get(item.buyerId).unAllocatedQuantity += (item.quantity - item.allocatedQuantity)
          // 紀錄已配未結的訂單 ID
          if (item.allocatedQuantity - item.checkOutQuantity > 0) participantsMap.get(item.buyerId).allocatedButUnCheckOutMerchOrderIds.push(item.id)
          participantsMap.get(item.buyerId).allocatedButUnCheckOutQuantity += Math.max(item.allocatedQuantity - item.checkOutQuantity, 0)
          if (item.quantity > item.checkOutQuantity) participantsMap.get(item.buyerId).unCheckOutQuantity ++
          participantsMap.get(item.buyerId).unCheckOutMoney += (item.quantity - item.checkOutQuantity) * item.price
          participantsMap.get(item.buyerId).moneyRangeOrder += item.price * item.quantity
        })
        // 帳單
        bills.forEach(item => {
          if (!participantsMap.has(item.buyerId)) {
            participantsMap.set(item.buyerId, JSON.parse(JSON.stringify(obj)))
          } 
          if (item.hierarchy === 0) {
            if (!item.topestParent.paid) {
              participantsMap.get(item.buyerId).unPaidBillIds.push(item.id)
              participantsMap.get(item.buyerId).unPaidQuantity ++
            }
            if (!item.topestParent.shipped) participantsMap.get(item.buyerId).unShippedQuantity ++
          }
          if (item.paid) participantsMap.get(item.buyerId).moneyRangeBill += item.totalPrice
          if (item.walletUsed) participantsMap.get(item.buyerId).moneyRangeBill -= item.walletUseAmount
          if ((item.hierarchy === 0 && item.shipped) || (item.hierarchy > 0 && item.topestParent.shipped)) console.log(item)
        })
        console.log(participantsMap)

        this.participantList = this.participantList.filter(item => {
          for (const [key, value] of participantsMap) {
            if (item.id === key) {
              item.unAllocatedQuantity = value.unAllocatedQuantity
              item.allocatedButUnCheckOutQuantity = value.allocatedButUnCheckOutQuantity
              item.unCheckOutMoney = value.unCheckOutMoney
              item.unCheckOutQuantity = value.unCheckOutQuantity
              item.unPaidQuantity = value.unPaidQuantity
              item.unShippedQuantity = value.unShippedQuantity
              item.moneyRangeOrder = value.moneyRangeOrder
              item.moneyRangeBill = value.moneyRangeBill
              item.allocatedButUnCheckOutMerchOrderIds = value.allocatedButUnCheckOutMerchOrderIds
              item.unPaidBillIds = value.unPaidBillIds
            } 
          }
          // 若為第一次載入，就跑進階搜尋 (配單/結單，未結單金額狀態，顧客習性 1，顧客習性 2，到貨狀態)
          if (this.updateStatus === 'first') return this.afterAdvancedSearch(item)
          else return true
        })
        console.log(this.participantList)
        this.tableAreaLoading = false
      }, 1)
    },
    // 後續需要帳單與訂單資料的進階搜尋 (配單/結單，未結單金額狀態，顧客習性 1，顧客習性 2，到貨狀態)
    afterAdvancedSearch(item) {
      // 配單/結單
      let matchAllocatedAndCheckout = false
      switch(this.advancedSearchData.singleSelect.allocatedAndCheckout) {
        case '已完成配單與結單':
          if (item.unAllocatedQuantity === 0 && item.unCheckOutMoney === 0) matchAllocatedAndCheckout = true
          break;
        case '未配單 > 0 且 已配未結 > 0':
          if (item.unAllocatedQuantity > 0 && item.allocatedButUnCheckOutQuantity > 0) matchAllocatedAndCheckout = true
          break;
        case '未配單 > 0':
          if (item.unAllocatedQuantity > 0) matchAllocatedAndCheckout = true
          break;
        case '已配未結 > 0':
          if (item.allocatedButUnCheckOutQuantity > 0) matchAllocatedAndCheckout = true
          break;
        case '不選擇':
          matchAllocatedAndCheckout = true
          break;
      }

      // 未結單金額狀態
      let matchUncheckoutStatus = false
      switch (this.advancedSearchData.singleSelect.uncheckoutStatus) {
        case '未結單金額 > 0':
          if (item.unCheckOutMoney > 0) matchUncheckoutStatus = true
          break;
        case '不選擇':
          matchUncheckoutStatus = true
          break;
      }
      
      // 顧客習性 1
      let matchparticipantHabitA = false
      switch (this.advancedSearchData.singleSelect.participantHabitA) {
        case '全部到貨但還尚有未結單的顧客':
          if (item.unAllocatedQuantity === 0 && item.unCheckOutQuantity > 0) matchparticipantHabitA = true
          break;
        case '不選擇':
          matchparticipantHabitA = true
          break;
      }

      // 顧客習性 2
      let matchparticipantHabitB = false
      switch (this.advancedSearchData.singleSelect.participantHabitB) {
        case '購物金不夠支付未結單的金額':
          if (item.wallet < item.unCheckOutMoney) matchparticipantHabitB = true
          break;
        case '不選擇':
          matchparticipantHabitB = true
          break;
      }

      // 到貨狀態
      let arrivalStatus = false
      switch (this.advancedSearchData.singleSelect.arrivalStatus) {
        case '全到貨':
          if (item.allocatedButUnCheckOutQuantity > 0 && item.unAllocatedQuantity === 0) arrivalStatus = true
          break;
        case '不選擇':
          arrivalStatus = true
          break;
      }

      return matchAllocatedAndCheckout && matchUncheckoutStatus && matchparticipantHabitA && matchparticipantHabitB && arrivalStatus
    },
    // * 取顧客 (end)
    // 準備 modal 內選擇幾筆資訊
    getSelectInfo(item) {
      this.selectItems = []
      this.propsSelectInfos.data = []
      this.selectItems.push(item)
      this.propsSelectInfos.data = this.selectItems
      console.log('選擇欄位:',this.selectItems)
    },
    // 編輯名稱 
    editName() {
      this.$methods.switchLoading('show')
      const vm = this
      const editNameApi = `${process.env.VUE_APP_API}/participant/updateVirtualUserName`
      const header = {
        authorization: this.serverToken,
      }
      const data = new FormData()
      data.append('participantId', this.selectItems[0].id)
      data.append('name', this.name)
      $.ajax({
        type: "PUT",
        async: true,
        url: editNameApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            // 更新資料
            vm.updateData('user', 'editNameModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 編輯暱稱
    editNickName() {
      this.$methods.switchLoading('show')
      const vm = this
      const editNickNameApi = `${process.env.VUE_APP_API}/participant/setAttribute`
      const header = {
        authorization: this.serverToken,
      }
      const data = []
      let obj = {
        id: this.selectItems[0].id,
        attributes: {
          setNickName: this.name
        }
      }
      data.push(obj)
      $.ajax({
        type: 'PUT',
        async: true,
        url: editNickNameApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 更新資料
            vm.updateData('participant', 'editNickNameModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 設定顧客群組
    setCustomerGroup() {
      this.$methods.switchLoading('show')
      const vm = this
      const setCustomerGroupApi = `${process.env.VUE_APP_API}/participant/setAttribute`
      const header = {
        authorization: this.serverToken,
      }
      const data = []
      let obj = {
        id: this.selectItems[0].id,
        attributes: {
          setCustomerGroupId: this.selectCustomerGroupId  !== 0 ? this.selectCustomerGroupId : null
        }
      }
      data.push(obj)
      $.ajax({
        type: 'PUT',
        async: true,
        url: setCustomerGroupApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 更新資料
            vm.updateData('customerGroup', 'editCustomerGroupModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 設定 VIP 等級
    setVip() {
      this.$methods.switchLoading('show')
      const vm = this
      const setVipApi = `${process.env.VUE_APP_API}/participant/setAttribute`
      const header = {
        authorization: this.serverToken,
      }
      const data = []
      this.selectItems.forEach(item => {
        let obj = {
          id: item.id,
          attributes: {
            setStoreVipId: this.selectVip  !== 0 ? this.selectVip : null
          }
        }
        data.push(obj)
      })
      $.ajax({
        type: 'PUT',
        async: true,
        url: setVipApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 更新資料
            vm.updateData('storeVip', 'setVipModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 設定禁止/開放個人賣場
    setForbidden(status) {
      this.$methods.switchLoading('show')
      const vm = this
      const setForbiddenApi = `${process.env.VUE_APP_API}/participant/setAttribute`
      const header = {
        authorization: this.serverToken,
      }
      const data = []
      let obj = {
        id: this.selectItems[0].id,
        attributes: {
          setForbidden: status
        }
      }
      data.push(obj)
      $.ajax({
        type: 'PUT',
        async: true,
        url: setForbiddenApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 更新資料
            vm.updateData('participant', 'setForbiddenModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 設定審核
    setVerify(status) {
      this.$methods.switchLoading('show')
      const vm = this
      const setVerifyApi = `${process.env.VUE_APP_API}/participant/setVerify`
      const header = {
        authorization: this.serverToken,
      }
      const participantIds = []
      this.selectItems.forEach(item => participantIds.push(item.id))
      const data = new FormData()
      data.append('participantIds', participantIds)
      data.append('verify', status)
      $.ajax({
        type: "PUT",
        async: true,
        url: setVerifyApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            // 更新資料
            vm.updateData('participant', 'verifyModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 新增購物金
    updateWallet() {
      if (this.updateWalletData.adjustment === '') return this.SweetAlert('other', '調整金額不為空值')
      if (this.updateWalletData.adjustment === 0) return this.SweetAlert('other', '調整金額不為零')
      this.$methods.switchLoading('show')
      const vm = this
      const updateWalletApi = `${process.env.VUE_APP_API}/participant/updateWallet`
      const header = {
        authorization: this.serverToken,
      }
      const data = []
      this.selectItems.forEach(item => {
        const obj = {
          id: item.id,
          adjustment: this.updateWalletData.adjustment,
          note: this.updateWalletData.note
        }
        data.push(obj)
      })
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateWalletApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 更新資料
            vm.updateData('participant', 'updateWalletModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 新增虛擬顧客
    createVirtualUser() {
      this.$methods.switchLoading('show')
      const vm = this
      const createVirtualUserApi = `${process.env.VUE_APP_API}/participant/createVirtualUser`
      const header = {
        authorization: this.serverToken,
      }
      const data = new FormData()
      data.append('storeId', this.storeId)
      data.append('name', this.name)
      $.ajax({
        type: "POST",
        async: true,
        url: createVirtualUserApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            vm.selectItems = [res.data]
            vm.updateData('getAll', 'virtualCustomerModal')
            // vm.SweetAlert(res.code, res.message)
            // vm.advancedSearch()
            // vm.virtualCustomerModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 匯出 Exce
    exportExcel() {
      const token = this.serverToken
      const ids = []
      this.selectItems.forEach(item => ids.push(item.id))
      const exportExportUrl = `${process.env.VUE_APP_API}/download/exportDataTableExcel?authorization=${token}&siteObjectTypeIndex=13&ids=${ids}`
      window.open(exportExportUrl, "_blank")
      this.exportExcelModal.hide()
      this.selectItems = []
    },
    // 刪除(復原)顧客
    setDeleted(status) {
      this.$methods.switchLoading('show')
      const vm = this
      const setDeletedApi = `${process.env.VUE_APP_API}/participant/setDeleted`
      const header = {
        authorization: this.serverToken,
      }
      const participantIds = []
      this.selectItems.forEach(item => participantIds.push(item.id))
      const data = new FormData()
      data.append('participantIds', participantIds)
      data.append('deleted', status)
      $.ajax({
        type: "PUT",
        async: true,
        url: setDeletedApi,
        headers: header,
        data: data,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function (res) {
          console.log(res)
          if (res.code === '200') {
            let modelName
            if (status) modelName = 'delModal'
            else modelName = 'restoreModal'
            // 更新資料
            vm.updateData('participant', modelName)
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // 批次轉換 VIP 身分
    batchSetVip() {
      if (this.batchOperate.before === '') return this.SweetAlert('other', '請選擇VIP身分')
      if (this.batchOperate.after === '') return this.SweetAlert('other', '請選擇VIP等級')
      this.$methods.switchLoading('show')
      const vm = this
      const batchSetVipApi = `${process.env.VUE_APP_API}/participant/setAttribute`
      const header = {
        authorization: this.serverToken,
      }
      if (this.batchOperate.before === 'null') this.batchOperate.before = null
      const data = []
      this.selectItems.forEach(item => {
        if (item.storeVipId === this.batchOperate.before) {
          let obj = {
            id: item.id,
            attributes: {
              setStoreVipId: this.batchOperate.after
            }
          }
          data.push(obj)
        }
      })
      $.ajax({
        type: 'PUT',
        async: true,
        url: batchSetVipApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 更新資料
            vm.updateData('storeVip', 'batchSetVipModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })     
    },
    // 批次轉換顧客群組
    batchSetCustomerGroup() {
      if (this.batchOperate.before === '' || this.batchOperate.after === '') return this.SweetAlert('other', '請選擇顧客群組')
      this.$methods.switchLoading('show')
      const vm = this
      const batchSetCustomerGroupApi = `${process.env.VUE_APP_API}/participant/setAttribute`
      const header = {
        authorization: this.serverToken,
      }
      if (this.batchOperate.before === 'null') this.batchOperate.before = null
      const data = []
      this.selectItems.forEach(item => {
        if (item.customerGroupId === this.batchOperate.before) {
          let obj = {
            id: item.id,
            attributes: {
              setCustomerGroupId: this.batchOperate.after
            }
          }
          data.push(obj)
        }
      })
      $.ajax({
        type: 'PUT',
        async: true,
        url: batchSetCustomerGroupApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            // 更新資料
            vm.updateData('customerGroup', 'batchSetCustomerGroupModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // * 合併顧客 (start)
    // 處理合併顧客選擇後是否有誤
    handleMergeParticipantError() {
      let errorMessage = null
      if (this.selectItems.length === 0) errorMessage = '請選擇一位顧客'
      if (this.selectItems.length > 1 ) errorMessage = '只能選擇一位顧客'
      if (this.selectItems.length === 1) {
        if (this.mergeParticipantStatus === 'p_merge_v' && this.selectItems[0].userType !== 'PHYSICALUSER') {
          // 實體併虛擬
          errorMessage = '只能選擇實體顧客'
        } else if (this.mergeParticipantStatus === 'v_merge_v' && this.selectItems[0].userType !== 'VIRTUALUSER') {
          // 虛擬併虛擬
          errorMessage = '只能選擇虛擬顧客'
        } else if (this.mergeParticipantStatus === 's_merge_s') {
          // 社群併社群
          if (this.selectItems[0].userType !== 'PHYSICALUSER') {
            errorMessage = '只能選擇實體顧客'
          } else {
            if (!this.selectItems[0].user.possibleToMerge) errorMessage = '您選擇的顧客已合併過'
            else {
              if (this.selectItems[0].userFacebookLink.appUserId && this.selectItems[0].userLineLink.appUserId) errorMessage = '只能選擇 "只綁定 FB" 或 "只綁定 Line" 的實體顧客'
              if (!this.selectItems[0].userFacebookLink.appUserId && !this.selectItems[0].userLineLink.appUserId) errorMessage = '請選擇 "只綁定 FB" 或 "只綁定 Line" 的實體顧客'
            }
          }
        }
      }
      return errorMessage
    },
    // 過濾被合併顧客列表
    filterParticipants() {
      this.participantsData = this.participantList.filter(item => {
        // 先撇除已選到的顧客
        if (item.id !== this.selectItems[0].id) {
          // 在依照合併狀態過濾
          if (this.mergeParticipantStatus === 'p_merge_v' && item.userType === 'VIRTUALUSER') {
            // 實體併虛擬
            return item
          } else if (this.mergeParticipantStatus === 'v_merge_v' && item.userType === 'VIRTUALUSER') {
            // 虛擬併虛擬
            return item
          } else if (this.mergeParticipantStatus === 's_merge_s' && item.userType === 'PHYSICALUSER') {
            // 社群併社群
            // 如果是選到綁定 FB 的實體顧客，就只過濾綁定 Line 實體顧客
            if (this.selectItems[0].userFacebookLink.appUserId && item.user.possibleToMerge) return !item.userFacebookLink.appUserId && item.userLineLink.appUserId
            // 如果是選到綁定 Line 的實體顧客，就只過濾綁定 Fb 實體顧客
            if (this.selectItems[0].userLineLink.appUserId && item.user.possibleToMerge) return item.userFacebookLink.appUserId && !item.userLineLink.appUserId
          }
        }
      })
    },
    // 合併顧客(實體併虛擬，虛擬併虛擬，line 併 fb)
    mergeParticipant() {
      if (!this.mergePhysicalUserChoose) return this.SweetAlert('other', '請選擇欲合併之實體顧客')
      this.$methods.switchLoading('show')
      const vm = this
      const mergeVirtualUserToPhysicalUserApi = `${process.env.VUE_APP_API}/participant/mergeParticipant`
      const header = {
        authorization: this.serverToken,
      }
      const data = new FormData()
      data.append('slaveId', this.mergePhysicalUserChoose.id)
      data.append('masterId', this.selectItems[0].id)
      $.ajax({
        type: 'POST',
        async: true,
        url: mergeVirtualUserToPhysicalUserApi,
        data: data,
        headers: header,
        processData: false,  // FormData 特有
        contentType: false,  // FormData 特有
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.selectItems.push(vm.mergePhysicalUserChoose)
            vm.updateData('mergeParticipant', 'mergeParticipantModal')
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // * 合併顧客 (end)
    // 發送 notify 訊息
    sendNotify() {
      if (this.checkIsSetOwnNotifyMsg && !this.notifyMsg) return this.SweetAlert('other', '請輸入傳送文字')
      this.$methods.switchLoading('show')
      // 組傳遞文字
      let message = ""
      if (!this.checkIsSetOwnNotifyMsg) {
        if (this.notifyStatus === 'groupA') message = `您好，您有已配單但未結單`
        else if (this.notifyStatus === 'groupB') message = `您好，您有已結單但未付款`
      } else {
        message = `賣家的話:\n${this.notifyMsg} \n\n`
      }
      // 圖片處理
      let files = document.querySelector("input[type=file]").files
      if (this.handleImages(files) === '圖片副檔名有誤') {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '圖片副檔名有誤')
      }
      let imgFile = null
      if (this.handleImages(files).length) {
        imgFile = this.handleImages(files)[0].files
      }
      // 整理傳遞至API資料
      const jsonString = []
      this.selectItems.forEach(item => {
        let msg = ""
        if (this.notifyStatus !== 'general') {
          // 如果是 (預設訊息前面須加名字)
          if (!this.checkIsSetOwnNotifyMsg) msg = `${item.name}${message}\n\n`
          else msg = `${message}訂單資訊:\n`
          // 組訂單資訊
          const merchandiseMap = this.notifyOtherData.get(item.id).merchandise
          const totalPrice = this.notifyOtherData.get(item.id).totalPrice
          for (const [merchandiseId, merchandiseValue] of merchandiseMap) {
            console.log(merchandiseValue)
            for (const [styleIds, styleValue] of merchandiseValue.style) {
              msg += `${merchandiseValue.merchandiseName} ${styleValue.styleName} 數量:${styleValue.quantity} 單價:${styleValue.lowerPrice} 小計:${styleValue.subTotal}元\n`
            }
          }
          msg += `總計: ${totalPrice}元(僅參考)`
        } else {
          msg += `\n${message}`
        }

        msg += `\n\n本訊息由管理群【${this.storeInfo.name}】於後台群發通知。`

        // 紀錄 api 資料
        let obj = {
          type: 13,
          id: item.id,
          message: msg,
        }
        console.log(msg)
        // 如果有圖片
        if (imgFile !== null) obj.imgFile = 0 
        jsonString.push(obj)
      })
      const data = new FormData()
      data.append('jsonString ', JSON.stringify(jsonString))
      data.append('imgFiles ', imgFile)
      this.notifyApi(data).then(res => {
        console.log(res)
        if (res.code === '200') {
          this.SweetAlert(res.code, res.message)
          this.selectItems = []
          this.notifyModal.hide()
        } else {
          this.SweetAlert(res.code, res.message)
        }
        this.$methods.switchLoading('hide')
      }).catch(err => {
        console.log(err)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // * 發送 motify api
    notifyApi(data) {
      const sendNotifyApi = `${process.env.VUE_APP_API}/apps/line/notify`
      const header = {
        authorization: this.serverToken,
      }
      const result = new Promise((resove, reject) => {
        $.ajax({
          type: 'POST',
          async: true,
          url: sendNotifyApi,
          data: data,
          headers: header,
          processData: false,  // FormData 特有
          contentType: false,  // FormData 特有
          success: resove,
          error: reject,
        })
      })
      return result
    },
    // * 取發布 notify 所需資料 API
    getNotitfyOtherInfo() {
      const getNotitfyOtherInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      let ids = []
      this.selectItems.forEach(item => {
        if (this.notifyStatus === 'groupA') ids.push(...item.allocatedButUnCheckOutMerchOrderIds)
        else ids.push(...item.unPaidBillIds)
      })
      console.log(ids)
      let data = []
      if (this.notifyStatus === 'groupA') {
        data = [{
          ids: ids,
          type: 26,
          methods: '{getMerchandise,getMerchandiseStyles}'
        }]
      } else {
        data = [{
          ids: ids,
          type: 27,
          methods: '{getBillBodies{getMerchOrder{getMerchandise,getMerchandiseStyles}}}'
        }]
      }
      const result = new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          async: true,
          url: getNotitfyOtherInfoApi,
          data: JSON.stringify(data),
          headers: header,
          contentType: 'application/json',
          success: resolve,
          error: reject,
        })
      })
      return result
    },
    // * 取發布 notify 所需資料之後的整理
    sortoutNotifyOtherData(item) {
      // 紀錄同買家
      if (!this.notifyOtherData.has(item.buyerId)) {
        this.notifyOtherData.set(item.buyerId, {
          merchandise: new Map(),
          totalPrice: 0
        })
      }
      // 紀錄同商品
      let merchandiseMap = this.notifyOtherData.get(item.buyerId).merchandise
      if (!merchandiseMap.has(item.merchandiseId)) {
        merchandiseMap.set(item.merchandiseId, {
          merchandiseName: item.merchandise.name,
          style: new Map()
        })
      }
      // 紀錄同款式
      let styleMap = merchandiseMap.get(item.merchandiseId).style
      let styleIdStr = ''
      let styleName = ''
      item.merchandiseStyles.forEach((styleItem, index, arr) => {
        styleIdStr += styleItem.id
        styleName += styleItem.name
        if (index < arr.length - 1) {
          styleIdStr += ","
          styleName += ","
        }
      })
      if (!styleMap.has(styleIdStr)) {
        styleMap.set(styleIdStr, {
          styleName: styleName,
          quantity: 0,
          lowerPrice: item.price,
          subTotal: 0 
        })
      }
      styleMap.get(styleIdStr).quantity += item.quantity
      if (styleMap.get(styleIdStr).lowerPrice > item.price) styleMap.get(styleIdStr).lowerPrice = item.price
      styleMap.get(styleIdStr).subTotal += this.$methods.numberToFixed(item.quantity * item.price)
    
      this.notifyOtherData.get(item.buyerId).totalPrice += this.$methods.numberToFixed(item.quantity * item.price)
    },
    // 來自<批次結單>子層的通知
    notifyParentFromBatchCheckOut(object) {
      console.log('批次結單:',object)
      console.log('選項:',this.selectItems)
      this.showBatchCheckOutModal = object.showBatchCheckOutModal
      if (object.reloadDataTable) {
        this.sortoutUpdateData(this.selectItems ,'getAll')
      }
    }
  }
}
</script>